import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import { PageHeader } from "../components/pageHeader/pageHeader";
import Layout from "../components/layout"
import SEO from "../components/seo"
import headerImage from "../../content/assets/pexels-dimitry-anikin-4942322.jpg"
import styles from "./contact.module.css"

const BlogIndex = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata?.title || `Title`

    return (
        <Layout location={location} title={siteTitle}>
            <SEO title="Contact" />
            <PageHeader title="Contact" image={headerImage} />

            <div className={styles.contactForm}>
                <form action="https://formspree.io/f/meqplyaj" method="POST">
                    <label>
                        Your email:
    <input type="text" name="_replyto" required/>
                    </label>
                    <label>
                        Message:
    <textarea name="message" required></textarea>
                    </label>

                    <button type="submit">Send</button>
                </form>
            </div>
        </Layout>
    )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
